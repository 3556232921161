var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"id":"CreateEdgeModuleCell"}},[(_vm.sequence)?_c('v-col',{staticClass:"d-flex justify-space-between align-center pb-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"primary--text text-subtitle-2"},[_vm._v(" Module #"+_vm._s(_vm._f("zeroPadFormatter")(_vm.sequence,3)))]),_c('div',[(_vm.sequence !== 1)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.removeCreateModuleObject(_vm.sequence)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,false,1325704326)},[_c('span',[_vm._v(" Remove This Device ")])]):_vm._e()],1)]):_vm._e(),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Module Name","placeholder":"Module Name ( modbus )","value":_vm.name},on:{"change":_vm.setModuleName}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"dense":"","outlined":"","clearable":"","label":"Module Type","item-value":"value","items":_vm.module_types,"value":_vm.type},on:{"change":_vm.setModuleType},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-subtitle-1 py-3"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"dense":"","outlined":"","clearable":"","label":"Module Architect","item-value":"value","items":_vm.module_architects,"value":_vm.architect},on:{"change":_vm.setModuleArchitect},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-subtitle-1 py-3"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Module Version","placeholder":"Module Version ( 3517 )","value":_vm.version},on:{"change":_vm.setModuleVersion}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"dense":"","outlined":"","clearable":"","label":"Registry","item-value":"value","items":_vm.registries,"value":_vm.registry},on:{"change":_vm.setModuleRegistry},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-subtitle-1 py-3"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","persistent-hint":"","label":"Communication Serial Port","placeholder":"Communication Serial Port","hint":"*Separate values by comma ( ex. PORT-1,PORT-2 )","value":_vm.getDisplaySerialPortText(_vm.serialPort)},on:{"change":_vm.setModulePort}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"primary--text text-subtitle-2"},[_vm._v(" Module Devices ")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"multiple":"","outlined":"","clearable":"","color":"primary","item-value":"_id","label":"Module Devices","item-text":function (item) { return item.deviceName + ' ( ' + item.deviceType + ' )'; },"items":_vm.device_list,"value":_vm.module_devices},on:{"change":_vm.setModuleDevices},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"close":"","small":"","color":"primary","input-value":selected},on:{"click:close":function($event){return _vm.removeSelectedDeviceByID(item._id)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.deviceName)+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"px-4 py-2 text-center"},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("No data available")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"grey--text text-caption"},[_vm._v("( please select site )")])])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-checkbox',{staticClass:"mt-3",attrs:{"hide-details":"","label":"Enable GCP Logging","value":_vm.enableLogging},on:{"change":_vm.setModuleLogging}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }