<template>
  <v-row id="CreateEdgeModuleCell">
    <v-col
      v-if="sequence"
      cols="12"
      class="d-flex justify-space-between align-center pb-0"
    >
      <span class="primary--text text-subtitle-2">
        Module #{{ sequence | zeroPadFormatter(3) }}</span
      >
      <div>
        <v-tooltip v-if="sequence !== 1" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              color="red"
              v-bind="attrs"
              v-on="on"
              @click="removeCreateModuleObject(sequence)"
            >
              <v-icon small>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span> Remove This Device </span>
        </v-tooltip>
      </div>
    </v-col>
    <v-col cols="4">
      <v-text-field
        dense
        outlined
        clearable
        label="Module Name"
        placeholder="Module Name ( modbus )"
        :value="name"
        @change="setModuleName"
      />
    </v-col>
    <v-col cols="4">
      <v-select
        dense
        outlined
        clearable
        label="Module Type"
        item-value="value"
        :items="module_types"
        :value="type"
        @change="setModuleType"
      >
        <template v-slot:item="{ item }">
          <div class="text-subtitle-1 py-3">
            {{ item.text }}
          </div>
        </template>
      </v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        dense
        outlined
        clearable
        label="Module Architect"
        item-value="value"
        :items="module_architects"
        :value="architect"
        @change="setModuleArchitect"
      >
        <template v-slot:item="{ item }">
          <div class="text-subtitle-1 py-3">
            {{ item.text }}
          </div>
        </template>
      </v-select>
    </v-col>
    <v-col cols="4">
      <v-text-field
        dense
        outlined
        clearable
        label="Module Version"
        placeholder="Module Version ( 3517 )"
        :value="version"
        @change="setModuleVersion"
      />
    </v-col>
    <v-col cols="4">
      <v-select
        dense
        outlined
        clearable
        label="Registry"
        item-value="value"
        :items="registries"
        :value="registry"
        @change="setModuleRegistry"
      >
        <template v-slot:item="{ item }">
          <div class="text-subtitle-1 py-3">
            {{ item.text }}
          </div>
        </template>
      </v-select>
    </v-col>
    <v-col cols="4">
      <v-text-field
        dense
        outlined
        clearable
        persistent-hint
        label="Communication Serial Port"
        placeholder="Communication Serial Port"
        hint="*Separate values by comma ( ex. PORT-1,PORT-2 )"
        :value="getDisplaySerialPortText(serialPort)"
        @change="setModulePort"
      />
    </v-col>
    <v-col cols="12" class="pb-0">
      <span class="primary--text text-subtitle-2"> Module Devices </span>
    </v-col>

    <v-col cols="8">
      <v-select
        multiple
        outlined
        clearable
        color="primary"
        item-value="_id"
        label="Module Devices"
        :item-text="(item) => item.deviceName + ' ( ' + item.deviceType + ' )'"
        :items="device_list"
        :value="module_devices"
        @change="setModuleDevices"
      >
        <template v-slot:selection="{ attrs, item, selected }">
          <v-chip
            close
            small
            color="primary"
            v-bind="attrs"
            :input-value="selected"
            @click:close="removeSelectedDeviceByID(item._id)"
          >
            {{ item.deviceName }}
          </v-chip>
        </template>
        <template v-slot:no-data>
          <div class="px-4 py-2 text-center">
            <span class="text-subtitle-1">No data available</span> <br />
            <span class="grey--text text-caption">( please select site )</span>
          </div>
        </template>
      </v-select>
    </v-col>
    <v-col cols="4">
      <v-checkbox
        class="mt-3"
        hide-details
        label="Enable GCP Logging"
        :value="enableLogging"
        @change="setModuleLogging"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" class="pb-0"> <v-divider></v-divider> </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "CreateEdgeModuleCell",
  props: [
    "edit_mode",
    "sequence",
    "name",
    "type",
    "registry",
    "version",
    "architect",
    "serialPort",
    "enableLogging",
    "deviceIds",
    "site_id",
  ],
  data() {
    return {
      module_name: null,
      module_port: null,
      module_version: null,
      module_devices: [],
      selected_type: null,
      selected_architect: null,
      selected_registry: null,
      is_enable_gcp_log: false,
      registries: [
        {
          text: "bpnsgpdiotedgecontainerregistry",
          value: "bpnsgpdiotedgecontainerregistry.azurecr.io",
        },
        {
          text: "nixtfarmdevregistry",
          value: "nixtfarmdevregistry.azurecr.io",
        },
      ],
      module_types: [
        { text: "Modbus", value: "modbus" },
        { text: "Logger", value: "logger" },
        { text: "Heart Beat", value: "heartbeat" },
      ],
      module_architects: [
        { text: "amd64", value: "amd64" },
        { text: "arm32v7", value: "arm32v7" },
        { text: "arm64v8", value: "arm64v8" },
      ],
    };
  },
  computed: {
    ...mapState({
      device_list: (state) => state.device.device_list,
      total_devices: (state) => state.device.total_devices,
      current_page: (state) => state.device.current_page,
    }),
    display_models: function () {
      if (this.selected_brand && this.models.length > 0) {
        return this.models.filter((item) => item.brand === this.selected_brand);
      }
      return [];
    },
  },
  watch: {
    // whenever question changes, this function will run
    site_id(value) {
      if (value) {
        this.setDeviceFilterLimit(null);
        this.setDeviceFilterSiteID(value);
        this.requestGetDeviceList();
      }
    },
    deviceIds(values) {
      console.log(values);
      if (values) {
        this.setModuleDevices(values);
      }
    },
  },
  filters: {
    zeroPadFormatter: function (value, length) {
      return String(value).padStart(length, "0");
    },
  },
  methods: {
    ...mapActions({
      requestGetDeviceList: "requestGetDeviceList",
    }),
    ...mapMutations({
      setDeviceFilterLimit: "SET_DEVICE_FILTER_LIMIT",
      setDeviceFilterSiteID: "SET_DEVICE_FILTER_SITE_ID",
      setCreateModuleObject: "SET_CREATE_MODULE_OBJECT",
      removeCreateModuleObject: "REMOVE_CREATE_MODULE_OBJECT",
    }),
    getDisplaySerialPortText(ports) {
      if (ports && ports.length > 0) {
        return ports.join(",");
      }
      return null;
    },
    updateModuleObjectField(sequence, field, value) {
      if (this.edit_mode) {
        this.$emit("patch", { field, value });
      } else {
        this.setCreateModuleObject({
          sequence,
          field,
          value,
        });
      }
    },
    setModuleName(value) {
      this.module_name = value;
      this.updateModuleObjectField(this.sequence, "name", value);
    },
    setModuleType(value) {
      this.selected_type = value;
      this.updateModuleObjectField(this.sequence, "type", value);
    },
    setModuleArchitect(value) {
      this.selected_architect = value;
      this.updateModuleObjectField(this.sequence, "architect", value);
    },
    setModuleVersion(value) {
      this.module_version = value;
      this.updateModuleObjectField(this.sequence, "version", value);
    },
    setModuleRegistry(value) {
      this.selected_registry = value;
      this.updateModuleObjectField(this.sequence, "registry", value);
    },
    setModulePort(value) {
      this.module_port = value;
      const req_values = value.replace(/\s+/g, "").split(",");
      this.updateModuleObjectField(this.sequence, "serialPort", req_values);
    },
    setModuleLogging(value) {
      this.is_enable_gcp_log = value;
      this.updateModuleObjectField(this.sequence, "enableLogging", value);
    },
    setModuleDevices(values) {
      this.module_devices = values;
      this.updateModuleObjectField(this.sequence, "deviceIds", values);
    },
  },
};
</script>

<style></style>
